import React, { useState, useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import setHours from "date-fns/setHours";
import setSeconds from "date-fns/setSeconds"
import setMinutes from "date-fns/setMinutes";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { readBuilderProgram } from 'typescript';

import * as consts from '../choices/formChoices';

function prettyApptDate(date) {
    var ps =  date.toDateString()
    // To show time use: 
    // var ps =  date.toLocaleString()
    // ps = ps.replace(/:\d+ (\w\w)$/, '$1');
    // ps = ps.replace(", ", ' @ ');
    
    return ps
}
function BookDateTime(props) {
    const nextPageId = "NameVehicle"

    const [apptDate, setApptDate] = useState(
        setSeconds(setHours(setMinutes(new Date(), 0), 9), 0)
    );

    const [pickupTimesOther, setPickupTimesOther] = useState(
        props.formData.pickupTimeOther
    );

    useEffect(() => {
        window.sessionStorage.setItem("next_page", nextPageId)
        document.getElementById('nextButton').disabled = (props.formData.pickupTimes === undefined)
        // document.getElementById('nextButton').disabled = !feeSructState

        // var form_data = JSON.parse(window.sessionStorage.getItem('form_data')) || {}
        // document.getElementById('submitButton').disabled = (pickupTimesOther === undefined)
    });

    // function handleOtherIssue(e) {
    //     e.preventDefault();
    //     let value = e.target.value;
    //     props.formData.pickupTimeOther = value
    
    //     window.sessionStorage.setItem(
    //         "form_data",
    //         JSON.stringify(props.formData)
    //     );
    //     // if(value.length >= 0) {
    //     //     document.getElementById('submitButton').disabled = false
    //     // }
    //     setPickupTimesOther(value)
    // }
    
    
    var fullyBookedDates = new Array()
    var blockedDateCnt = {}
    var blockedDatesStrs = JSON.parse(window.sessionStorage.getItem('blocked_dates')) || undefined
    var maxAllowedDateStrs = JSON.parse(window.sessionStorage.getItem('max_allowed_dates')) || undefined
    var maxAllowedCnt = {}

    if(blockedDatesStrs !== undefined && maxAllowedDateStrs !== undefined) {
        // First hash the max allowed dates
        maxAllowedDateStrs['dateStrs'].some(function (value) {
            
            var date = new Date(value.date)
            date.setHours(date.getHours()+8); // hack to offset the hours
            date.setSeconds(0)

            // console.log("proc: " + value.date + ", vs " + date)

            var dateHash = value.date.substring(0, 10) // copies the date part of the string
            maxAllowedCnt[dateHash] = value.max_allowed

            
            // If 0 days are allowed, fullbook that date
            if(value.max_allowed <= 0) {
                fullyBookedDates.push(date)
                // console.log('fully booked: ' + date)
            }
        });
        
        // Set everything to 0 first
        blockedDatesStrs['dateStrs'].some(function (value) {
            var datetime = new Date(value)
            var dateHash = value.substring(0, 10) // copies the date part of the string

            blockedDateCnt[dateHash]=0
        });
        

        blockedDatesStrs['dateStrs'].some(function (value) {
            var datetime = new Date(value)
            var dateHash = value.substring(0, 10) // copies the date part of the string
            
            if(dateHash in blockedDateCnt) {
                blockedDateCnt[dateHash] += 1;
            }

            if(blockedDateCnt[dateHash] >= maxAllowedCnt[dateHash]) {
                
                fullyBookedDates.push(datetime)
            }
            
        });

        fullyBookedDates.push(new Date())
        fullyBookedDates = fullyBookedDates.sort((a, b) => a - b)

        // console.log("fully booked: " + fullyBookedDates)
    }

    // make sure the apptdate is not fully booked
    fullyBookedDates.forEach(function (value) {
        let d = value

        // start on a monday if we are curretnly in a weekend
        if(apptDate.getDay() === 6 || apptDate.getDay() === 0) {
            if(apptDate.getDay() === 6) {
                apptDate.setDate(apptDate.getDate() + 2)    
            } else {
                apptDate.setDate(apptDate.getDate() + 1)    
            }
        }

        // console.log(d + " vs " + apptDate)
        if(d.getDate() === apptDate.getDate() && d.getMonth() === apptDate.getMonth() && d.getFullYear() === apptDate.getFullYear()) {
            apptDate.setDate(apptDate.getDate() + 1)

            if(apptDate.getDay() === 6 || apptDate.getDay() === 0) {
                if(apptDate.getDay() === 6) {
                    apptDate.setDate(apptDate.getDate() + 2)    
                } else {
                    apptDate.setDate(apptDate.getDate() + 1)    
                }
            }
            
            // fullyBookedDates make sure the date is not in 
            
            // console.log("inc date: " + apptDate)
        }

        props.onChange(apptDate)
    });

    const [timeSlot, setTimeSlot] = useState('');

    var availableTimes = new Array()
    var availableDatesStrs = JSON.parse(window.sessionStorage.getItem('available_dates')) || undefined
    if(availableDatesStrs !== undefined) {
        availableDatesStrs['dateStrs'].forEach(function (value) {
            var d = new Date(value)
            let date = apptDate

            if(d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear()) {

                let timeStr = d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                // console.log(timeStr);

                availableTimes.push({timeStr: timeStr, date: d})
                
            }
        });

        if(availableTimes.length >= 1) {
            var isTimeSlotValid = false
            availableTimes.map((time, idx) => {
                if(timeSlot === time.timeStr) {
                    isTimeSlotValid = true
                    return true
                }
            })
            
            if(timeSlot !== availableTimes[0].timeStr && isTimeSlotValid === false)
            {
                apptDate.setHours(availableTimes[0].date.getHours(), availableTimes[0].date.getMinutes())
                setTimeSlot(availableTimes[0].timeStr)
            }
            
        } else {
            apptDate.setHours(9, 0)
            if(timeSlot !== '') {
                setTimeSlot('')
            }
        }
    }

    var isWeekday = date => {
        const day = date.getDay();
        let isWeekend = (day === 0 || day === 6);
        return !isWeekend
    };

    let validateBookingDate = (date) => {
        // Make sure the seleced date falls within the available dates
        // availableTimes.some(function (value) {
        //     if(date.getDate() === value.getDate()) {
        //         props.onChange(date)
        //         return true
        //     }
        // })
        
        props.onChange(date)
        setApptDate(date)
    }

    let updateTimeSelection = (timeObj) => {
        // let newApptDate = apptDate
        apptDate.setHours(timeObj.date.getHours(), timeObj.date.getMinutes())
        setTimeSlot(timeObj.timeStr)
        props.onChange(apptDate)
        // setApptDate(newApptDate)
    }


    

    const timeVariants = [
        'primary',
        'light'
    ]

    // console.log('slot: ' + timeSlot)

    return (
        
        <div>
        <h2><Badge variant="primary">Appointment Booking</Badge></h2>
        {/* <Card>
        <Card.Body>
        <Form.Label>Our shop labor rate is $115/hour for labor and does not include the cost of parts used. The cost for initial diagnosis starts at $80, approximately 40 minutes of shop time. In limited cases, this may be enough to resolve simple issues. All vehicles receive a free courtesy inspection that includes a review of most fluids, drive belt(s), and battery inspection. </Form.Label>
        </Card.Body>
        </Card> */}
        
        <p></p>
        <Card>
        <Card.Body>
        <Form.Group>
            <Form.Label><h5>Select a date and time for vehilce drop off</h5><div class="required_text">*required</div></Form.Label>
            
            <div style={{textAlign:"center"}}>
            <DatePicker
                // selected={props.apptDate}
                selected={apptDate}
                onChange={function(date) {validateBookingDate(date)} }
                // onChange={apptDate => setApptDate(event.target.value)}
                // onChange={function(date) {props.onChange(date); forceUpdate()} }
                // onChange={function(date) {props.onChange(date)} }
                // showTimeSelect
                // minTime={setHours(setMinutes(new Date(), 0), 9)}
                // maxTime={setHours(setMinutes(new Date(), 0), 17)}
                minDate={new Date()}
                maxDate={new Date(apptDate.getFullYear(), apptDate.getMonth() + 6, apptDate.getDay())} // book 6 months out

                // includeTimes={availableTimes}
                // injectTimes={[
                //     setHours(setMinutes(new Date(), 0), 9),
                //     setHours(setMinutes(new Date(), 0), 11),
                //     setHours(setMinutes(new Date(), 30), 14)
                // ]}

                excludeDates={fullyBookedDates}
                filterDate={isWeekday}
                timeFormat="hh:mm aa"
                // timeIntervals={60}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                inline={true}
            />

            {/* <ButtonGroup>
                {availableTimes.map((time, idx) => (
                <Button
                    key={idx}
                    type="button"
                    animation="border"
                    variant={timeSlot === time.timeStr ? 'primary' : 'light'}
                    name="radio"
                    value={time.timeStr}
                    checked={timeSlot === time.timeStr}
                    onClick={(e) => updateTimeSelection(time)}
                >
                    {time.timeStr}
                </Button>
                ))}
            </ButtonGroup> */}
            <p> </p>
            <h4>
                Selected: {prettyApptDate(apptDate)}
            </h4>
            <div>Our shop opens at 9am.  We expect you to drop your car off between 9 and 9:30 if you can, this helps us plan our day to get your vehicle repaired as soon as possible.  If you need to drop off after 10am OR before we open at 9am please let us know when we confirm your appointment.  Thank You!</div>
            <div>Contact <a href='mailto:deanscarcare@gmail.com'>deanscarcare@gmail.com</a> if you'd like to schedule an appointment at different time.</div>

            </div>

        </Form.Group>
        </Card.Body>
        </Card>

        <p></p>
        <Card>
        <Card.Body>
        <Form.Group>
            {/* <Form.Label><h5>Pickup Details</h5></Form.Label> */}
            <Form.Label>If the repair is an emergency, needs to be towed in asap, or is time sensitive, please text us at (503) 308-9685 after booking an appointment slot, and we can see about getting you in right away.</Form.Label>
            {/* <Form.Check 
                id="rep1"
                type="checkbox"
                label={consts.pickupData[0]}
                checked={props.formData.pickupTimes[0] === true}
                onChange={function(e) {updatePickupTimes(0, props.formData)} }
                
            />
            <Form.Check 
                id="rep2"
                type="checkbox"
                label={consts.pickupData[1]}
                checked={props.formData.pickupTimes[1] === true}
                onChange={function(e) {updatePickupTimes(1, props.formData)} }
                
            />
            <Form.Check 
                id="rep3"
                type="checkbox"
                label={consts.pickupData[2]}
                checked={props.formData.pickupTimes[2] === true}
                onChange={function(e) {updatePickupTimes(2, props.formData)} }
                
            />
            <Form.Check 
                id="rep4"
                type="checkbox"
                label={consts.pickupData[3]}
                checked={props.formData.pickupTimes[3] === true}
                onChange={function(e) {updatePickupTimes(3, props.formData)} }
                
            />
            <Form.Check 
                id="rep5"
                type="checkbox"
                label={consts.pickupData[4]}
                checked={props.formData.pickupTimes[4] === true}
                onChange={function(e) {updatePickupTimes(4, props.formData)} }
                
            />
            <Form.Check 
                id="rep6"
                type="checkbox"
                label={consts.pickupData[5]}
                checked={props.formData.pickupTimes[5] === true}
                onChange={function(e) {updatePickupTimes(5, props.formData)} }
                
            />
            <Form.Check 
                id="rep7"
                type="checkbox"
                label={consts.pickupData[6]}
                checked={props.formData.pickupTimes[6] === true}
                onChange={function(e) {updatePickupTimes(6, props.formData)} }
                
            />
            <Form.Check 
                id="rep8"
                type="checkbox"
                label={consts.pickupData[7]}
                checked={props.formData.pickupTimes[7] === true}
                onChange={function(e) {updatePickupTimes(7, props.formData)} }
                
            /> */}
            {/* <Form.Check 
                id="rep9"
                type="checkbox"
                label={consts.pickupData[8]}
                checked={props.formData.pickupTimes[8] === true}
                onChange={function(e) {updatePickupTimes(8, props.formData)} }
                
            /> */}
            {/* <Form.Control 
                id="otherIssue" type="text" placeholder="Pickup details"
                defaultValue={props.formData.pickupTimeOther}
                onChange={(e) => handleOtherIssue(e)}/> */}
        </Form.Group>
        </Card.Body>
        </Card>

        
        </div>
    );
}



export default BookDateTime;