import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

import * as consts from '../choices/formChoices';


function Purpose(props) {
    const nextPageId = "Purpose"

    const [purposeState, setPurposeState] = useState(
        Array(5).fill(false)
    );

    let updatePurposeData = (formData, index) => {
        var newPurposeState = purposeState.slice()
        newPurposeState[index] = !newPurposeState[index]
        
        // Clear out all inspection related choices
        if(index === 0 && newPurposeState[index] === false) {
            newPurposeState[3] = false
            newPurposeState[4] = false
            newPurposeState[5] = false
        }

        if(index == 3) {
            newPurposeState[4] = false
            newPurposeState[5] = false
        } else if(index == 4) {
            newPurposeState[3] = false
            newPurposeState[5] = false
        } else if(index == 5) {
            newPurposeState[3] = false
            newPurposeState[4] = false
        }

        formData.purposeOptions = newPurposeState 
        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
        );

        setPurposeState(newPurposeState)
    }


    function handleSecondaryIssue(formData, e) {
        e.preventDefault();
        let value = e.target.value;
        formData.secondaryIssue = value
        // console.log("SETTING SECOND ISSUE: " + value)
        // this.setState({update: 'g'})   

        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
        );
    }
    useEffect(() => {

        var arrayLength = purposeState.length;
        
        let disableNextButton = true
        for (var i = 0; i < arrayLength; i++) {
            let item = purposeState[i]
            if(item === true) {
                disableNextButton = false
                break;
            }
        }

        window.sessionStorage.setItem("next_page", nextPageId)
        // document.getElementById('nextButton').disabled = true

        document.getElementById('submitButton').disabled = disableNextButton
    });
    
    return (
        <div>
        <h2><Badge variant="primary">Purpose</Badge></h2>
        <Card>
        <Card.Body>
        <Container>
            <Form.Group controlId="purpose">
                <Form.Label>Check all that apply.<div class="required_text">*required</div></Form.Label>
                <Form.Check 
                    type="checkbox"
                    id="purpose3"
                    label={consts.purposeData[2]}
                    checked={props.formData.purposeOptions[2] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 2);} }    
                />
                <Form.Check
                    type="checkbox"
                    id="purpose0"
                    label={consts.purposeData[0] + " - An inspection is not a diagnostic. More like a routine exam of the vehicle / the general report of how things currently are.  We perform a courtesy inspection of every car that comes through the shop, this is a brief look over some engine bay fluids, belts and wipers.  Oil changes (LOF) come with a more thorough version of that."} 
                    checked={props.formData.purposeOptions[0] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 0);} }    
                />
                {props.formData.purposeOptions[0] === true && 
                    // <Row><Col xs={1}></Col><Col>
                    <Form.Check 
                    type="radio"
                    id="purpose4"
                    label={consts.purposeData[3] + " - add this to your oil change (LOF) if you want us to look at your brakes.  Or we can just look at your brakes!"}
                    checked={props.formData.purposeOptions[3] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 3);} }
                    />
                    //</Form.Group></Col><Col></Col></Row>
                }

                {props.formData.purposeOptions[0] === true && 
                    // <Row><Col xs={1}></Col><Col>
                    <Form.Check 
                    type="radio"
                    id="purpose5"
                    label={consts.purposeData[4] + " - Good routine follow up.  Add this to your oil change?  Include inspecting most fluids, suspension, brakes etc. and test drive.  This inspection usually takes one to two hours."}
                    checked={props.formData.purposeOptions[4] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 4);} }
                    />
                    //</Col><Col></Col></Row>
                }

                {props.formData.purposeOptions[0] === true && 
                    // <Row><Col xs={1}></Col><Col>
                    <Form.Check 
                    type="radio"
                    id="purpose6"
                    label={consts.purposeData[5] + " - Our most comprehensive inspection that looks over all systems of vehicle excluding: air conditioning (A/C), interior accessories.  You probably need an oil change too!  This inspection takes several hours."}
                    checked={props.formData.purposeOptions[5] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 5);} }
                    />
                    //</Col><Col></Col></Row>
                }
{/* 
                <Form.Check 
                    type="checkbox"
                    id="purpose1"
                    label={consts.purposeData[1]}
                    checked={props.formData.purposeOptions[1] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 1);} }
                /> */}
                <Form.Check 
                    type="checkbox"
                    id="purpose2"
                    label={consts.purposeData[1] + " - this is a diagnostic of any specific symptom or concern. Ex Check Engine Light is on, horrible noise while turning to the right, Failed DEQ, or heater blows the wrong kind of air... or let us know if you have all of the above."}
                    checked={props.formData.purposeOptions[1] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 1);} }
                />

            </Form.Group>
            </Container>
        </Card.Body>
        </Card>


        <p></p>

        <Card>
        <Card.Body>
        <Form.Group controlId="secndaryissue">
            <Form.Label>Please describe your issues you'd like us to look at, a general budget and we can reach out to you.</Form.Label>
            <Form.Control type="text" placeholder="Your answer" defaultValue={props.formData.secondaryIssue} onChange={(e) => handleSecondaryIssue(props.formData, e)}/>
        </Form.Group>
        </Card.Body>
        </Card>
        </div>
    )
}


export default Purpose;












